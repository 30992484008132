"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetForumDetailsForCourse = exports.GetForumDetailsForCourseOperationId = void 0;
const ClientController_1 = require("../../../../../../ClientController");
exports.GetForumDetailsForCourseOperationId = 'GetForumDetailsForCourse';
/**
 * Gets the forum details based on the course details
 *
 * @path /forums/details/:educatorSlug/:courseSlug
 * @generated
 */
exports.GetForumDetailsForCourse = (0, ClientController_1.ClientControllerFactory)({
    path: '/forums/details/:educatorSlug/:courseSlug',
    method: 'GET',
    definedStatusCodes: [200, 404, 400, 500],
});
